<template>
  <svg viewBox="0 0 905 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <clipPath id="test">
        <path d="M0 0H905V940.962L0 759V0Z" fill="#D9D9D9"/>
      </clipPath>

      <filter id="darken" x="0" y="0" width="100%" height="100%">
        <feComponentTransfer>
          <feFuncR type="linear" slope="0.5"/>
          <feFuncG type="linear" slope="0.5"/>
          <feFuncB type="linear" slope="0.5"/>
        </feComponentTransfer>
      </filter>
    </defs>
    <image :href="heroImageUrl" width="905" height="1000" :clip-path="'url(#test)'" preserveAspectRatio="xMidYMax slice" :style="imageStyle"></image>
  </svg>
</template>

<script>
import HeroImage from '@/assets/HeroPicture.jpg';
import { ref, computed } from 'vue';

export default {
  data() {
    return {
      heroImageUrl: HeroImage,
    }
  },
  setup() {
    const width = ref(window.innerWidth);
  
    const breakpoint = 830;
    const isUnderBreakpoint = computed(() => width.value < breakpoint);

    window.addEventListener('resize', () => {
      width.value = window.innerWidth;
    });

    const imageStyle = computed(() => {
      return isUnderBreakpoint.value ? { filter: 'url(#darken)' } : {};
    });

    return { imageStyle };
  },
}
</script>
