<template>
  <footer class="bg-background-color flex items-center text-white text-center p-4 shadow-upward h-36 lg:text-2xl text-xl">
    <div class="container mx-auto flex justify-end px-8">
      <nav class="space-x-6">
        <router-link to="/" class="hover:text-primary font-bold" active-class="text-primary-light">Etusivu</router-link>
        <router-link to="/palvelut" class="hover:primary font-bold" active-class="text-primary-light">Palvelut</router-link>
        <router-link to="/yhteystiedot" class="hover:primary font-bold" active-class="text-primary-light">Yhteystiedot</router-link>
      </nav>
    </div>
  </footer>
</template>
<script>
export default {
  name: "SiteFooter",
};
</script>
<style scoped>
  .shadow-upward {
    -webkit-box-shadow: 0px -10px 15px -3px rgba(0, 0, 0, 0.1), 0px -4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0px -10px 15px -3px rgba(0, 0, 0, 0.1), 0px -4px 6px -2px rgba(0, 0, 0, 0.05);
  }
</style>
