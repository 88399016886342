<template>
  <div
    class="flex flex-col justify-center min-h-screen bg-background-color background-lines py-24 text-lg sm:text-xl lg:text-2xl"
  >
    <div
      class="w-full lg:w-2/3 mx-auto p-6 bg-secondary shadow-lg rounded-lg flex flex-col justify-between"
    >
      <div class="mb-24">
        <h2
          class="text-2xl break:text-3xl font-semibold text-primary-light mb-6"
        >
          Yhteystiedot
        </h2>
        <div class="flex justify-between mt-4">
          <div class="w-1/2">
            <p class="font-semibold text-primary-light text-2xl break:text-3xl">
              Osoite
            </p>
            <p class="text-text-color">Ritalantie 1, 03100 Nummela</p>
          </div>
          <div class="w-1/2">
            <p class="font-semibold text-primary-light text-2xl break:text-3xl">
              Y-tunnus
            </p>
            <p class="text-text-color">3395662-3</p>
          </div>
        </div>
        <hr class="my-6 border-t border-primary-dark" />
        <div class="flex flex-wrap justify-between mt-4">
          <div class="flex-1 min-w-1/2">
            <p
              class="font-semibold text-primary-light text-2xl break:text-3xl"
            >
              Sähköposti
            </p>
            <p class="text-text-color">veeti@kiinteistöhuoltovh.fi</p>
          </div>
          <div class="flex-1 min-w-1/2">
            <p class="font-semibold text-primary-light text-2xl break:text-3xl">
              Puhelin
            </p>
            <p class="text-text-color">+358 4015 62228</p>
          </div>
        </div>
        <hr class="my-6 border-t border-primary-dark" />
        <div class="mt-4">
          <p class="font-semibold text-primary-light text-2xl break:text-3xl">
            Toimialue
          </p>
          <p class="text-text-color">Vihti, Lohja, Kirkkonummi</p>
        </div>
      </div>
      <div class="w-full flex justify-center pb-12">
        <div class="w-full lg:w-2/3">
          <h2
            class="text-2xl break:text-3xl font-semibold text-primary-light mb-4"
          >
            Lähetä Viesti
          </h2>
          <form class="flex flex-col">
            <input
              type="text"
              placeholder="Nimi"
              class="border-b p-2 mb-4 bg-secondary text-text-color border-primary-dark"
            />
            <input
              type="email"
              placeholder="Sähköposti"
              class="border-b p-2 mb-4 bg-secondary text-text-color border-primary-dark"
            />
            <input
              type="text"
              placeholder="Aihe"
              class="border-b p-2 mb-4 bg-secondary text-text-color border-primary-dark"
            />
            <textarea
              placeholder="Viestisi"
              class="border p-2 mb-4 bg-secondary text-text-color border-primary-dark"
            ></textarea>
            <button
              type="submit"
              class="border border-primary-dark border text-white p-2 hover:bg-orange-400"
            >
              Lähetä
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
  methods: {
    submitForm() {
      alert("Form submitted! Replace this with actual form processing.");
    },
  },
};
</script>

<style>
.background-lines {
  background: linear-gradient(
      310deg,
      transparent 45%,
      orange 45%,
      orange 55%,
      transparent 55%
    ),
    linear-gradient(
      10deg,
      transparent 45%,
      orange 45%,
      orange 55%,
      transparent 55%
    );
  background-size: cover;
}
button:hover {
  color: #ffffff;
  transition: background-color 0.2s ease-in-out;
}
button:active {
  transform: scale(0.98);
  transition: transform 0.1s ease;
}
input:focus,
textarea:focus,
button:focus {
  outline: none;
  border-color: orange;
}
</style>
