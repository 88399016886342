<template>
  <header
    class="bg-background-color flex items-center text-white drop-shadow-md relative z-40 h-36"
  >
    <div class="container mx-auto flex items-center justify-between py-4 px-4">
      <!-- Logo -->
      <div class="flex items-center">
        <img
          :src="require('@/assets/KVHLogoNoWindows.svg')"
          alt="Logo"
          class="h-20 sm:h-28"
        />
        <div class="flex flex-col items-center">
          <div
            class="text-titleSmall sm:text-title font-bold text-center"
          >
            KVH
          </div>
          <p class="sm:text-underTitle text-underTitleSmall flex">
            Kiinteistöhuolto Veeti Huotari
          </p>
        </div>
      </div>
      <!-- Desktop Navigation Links -->
      <nav class="hidden burger:flex space-x-6">
        <router-link
          to="/"
          class="hover:text-primary text-2xl font-bold"
          active-class="text-primary-light"
          >Etusivu</router-link
        >
        <router-link
          to="/palvelut"
          class="hover:text-primary text-2xl font-bold"
          active-class="text-primary-light"
          >Palvelut</router-link
        >
        <router-link
          to="/yhteystiedot"
          class="hover:text-primary text-2xl font-bold"
          active-class="text-primary-light"
          >Yhteystiedot</router-link
        >
      </nav>
      <!-- Burger Menu Button -->
      <button @click="toggleMenu" class="burger:hidden">
        <div class="space-y-1.5">
          <div
            :class="{
              'transform rotate-45 translate-y-1.5': isMenuOpen,
              'bg-current h-0.5 w-6 block transition duration-500': true,
            }"
          ></div>
          <div
            :class="{
              'opacity-0': isMenuOpen,
              'bg-current h-0.5 w-6 block transition duration-500': true,
            }"
          ></div>
          <div
            :class="{
              'transform -rotate-45 -translate-y-2.5': isMenuOpen,
              'bg-current h-0.5 w-6 block transition duration-500': true,
            }"
          ></div>
        </div>
      </button>
      <!-- Mobile Navigation Links -->
      <transition
        name=""
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <div
          v-if="isMenuOpen"
          class="w-full absolute top-full right-0 bg-background-color shadow-md py-2 z-50 dropdown_menu dropdown_menu--animated dropdown_menu-7"
        >
          <router-link
            to="/"
            class="block px-4 py-2 font-bold hover:text-primary"
            active-class="active-link"
            @click="toggleMenu"
            >Etusivu</router-link
          >
          <router-link
            to="/palvelut"
            class="block px-4 py-2 font-bold hover:text-primary"
            active-class="active-link"
            @click="toggleMenu"
            >Palvelut</router-link
          >
          <router-link
            to="/yhteystiedot"
            class="block px-4 py-2 font-bold hover:text-primary"
            active-class="active-link"
            @click="toggleMenu"
            >Yhteystiedot</router-link
          >
        </div>
      </transition>
    </div>
  </header>
</template>
<script>
export default {
  name: "SiteHeader",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    beforeEnter(el) {
      el.style.animation = "";
    },
    enter(el, done) {
      el.style.animation = "rotateMenu 400ms ease-in-out forwards";
      el.addEventListener("animationend", done);
    },
    leave(el, done) {
      el.style.animation = "rotateMenuOut 400ms ease-in-out forwards";
      el.addEventListener("animationend", done);
    },
  },
};
</script>

<style>
.site-header {
  position: relative;
  z-index: 1000;
}
@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes rotateMenuOut {
  0% {
    transform: rotateX(0deg);
  }
  30% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(-90deg);
    display: none;
  }
}
.dropdown_menu-7 {
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}
</style>
