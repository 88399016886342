<template>
  <a
    class="cursor-pointer relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-grey-50 transition-all duration-150 ease-in-out hover:pl-10 hover:pr-6 bg-primary group"
    @click="onClick"
  >
    <span
      :class="[
        'absolute bottom-0 left-0 h-full w-full h-1 transition-all duration-150 ease-in-out group-hover:h-full',
        hoverColorClass,
      ]"
    ></span>
    <span
      v-if="iconType === 'arrow'"
      class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12"
    >
      <svg
        class="w-5 h-5 text-text-color"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        ></path>
      </svg>
    </span>
    <span
      v-if="iconType === 'arrow'"
      class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200"
    >
      <svg
        class="w-5 h-5 text-primary-dark"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        ></path>
      </svg>
    </span>
    <span
      v-if="iconType === 'call'"
      class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12"
    >
      <svg
        class="w-5 h-5 text-text-color"
        fill="white"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
      <path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z"></path>
      </svg>
    </span>
    <span
      v-if="iconType === 'call'"
      class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200"
    >
      <svg
        class="w-5 h-5 text-text-color"
        fill="#fd7f00"
        stroke="#fd7f00"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
      <path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z"></path>
      </svg>
    </span>
    <span
      class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white"
      >{{ text }}</span
    >
  </a>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    text: {
      type: String,
      default: "Default Button Text",
    },
    path: {
      type: String,
      default: "",
    },
    hoverStyle: {
      type: String,
      default: "secondary",
    },
    iconType: {
      type: String,
      default: "arrow",
    },
  },
  computed: {
    hoverColorClass() {
      return this.hoverStyle === "secondary"
        ? "hover-secondary"
        : "hover-background-color";
    },
  },
  methods: {
    onClick() {
      if (this.path.startsWith("tel:")) {
        window.location.href = this.path;
      } else if (this.path) {
        this.$router.push(this.path);
      }
    },
  },
};
</script>

<style scoped>
.group:hover .hover-secondary {
  background-color: #222526 !important;
}

.group:hover .hover-background-color {
  background-color: #191c1d !important;
}
</style>
