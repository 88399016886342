<template>
  <div
    class="bg-background-color flex min-h-screen align-center justify-center text-xl lg:text-2xl"
  >
    <div class="bg-svg-background min-h-screen w-full">
      <div
        class="flex flex-row flex-wrap py-72 justify-center items-center text-text-color bg-svg-background"
      >
        <div class="text-text-color w-1/2 xl:w-1/3">
          <div class="text-left mx-6 my-6">
            <h1
              class="text-3xl break:text-4xl font-bold my-6 text-primary-light"
            >
              Kiinteistöhuollon työt
            </h1>
            <ul class="space-y-2">
              <li>Ruohonleikkaus</li>
              <li>Pensaiden ja puiden leikkaus</li>
              <li>Rikkaruohojen kitkeminen</li>
              <li>Istutusten hoito ja kastelu</li>
              <li>Aidan huolto ja maalaus</li>
              <li>Polkujen ja kulkureittien puhdistus ja korjaus</li>
              <li>Rännien tyhjennykset</li>
            </ul>
          </div>
          <div class="text-left mx-6 my-6 py-16">
            <h1
              class="text-3xl break:text-4xl font-bold my-6 text-primary-light"
            >
              Lumityöt
            </h1>
            <ul class="space-y-2">
              <li>Jäätymisen torjunta (hiekoitus ja suolaus)</li>
              <li>Katolta lumen poisto</li>
              <li>Polkujen linkous ja hiekoitus</li>
              <li>Piha-alueiden hoito</li>
              <li>Lumivallien ja kinosten siirtäminen</li>
            </ul>
          </div>
        </div>
        <div class="text-text-color mt-64 w-1/2 xl:w-1/3">
          <div class="text-left">
            <h1
              class="text-3xl break:text-4xl font-bold my-6 text-primary-light"
            >
              Kiinteistön yleishuolto ja -siivous
            </h1>
            <ul class="space-y-2">
              <li>Terassien öljyäminen</li>
              <li>Pintojen puhdistus (lattiat, seinät, ikkunat jne.)</li>
              <li>Roskakorien tyhjennys ja jätehuolto</li>
              <li>Käytävien ja yleisten tilojen siivous</li>
              <li>Pölynimurointi ja lattioiden pesu</li>
              <li>Palovaroittimien tarkastukset</li>
            </ul>
            <div class="text-left my-12 py-16">
              <h1
                class="text-3xl break:text-4xl font-bold my-6 text-primary-light"
              >
                Asennukset
              </h1>
              <ul class="space-y-2">
                <li>Televisiot ja muu elektroniikka</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesPage",
};
</script>

<style>
.bg-svg-background {
  background-image: url("~@/assets/ServicesBg.svg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 80%;
}
</style>
