<template>
  <div class="bg-background-color h-100% text-text-color text-xl lg:text-2xl">
    <!-- Hero Section -->
    <div class="relative w-full h-half-screen bg-center bg-cover">
      <div class="flex-container">
        <div
          class="flex justify-center items-center relative hidden break:block"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1015 852"
            fill="none"
          >
            <path d="M0 0H1015V851.473L0 665.603V0" fill="#222526" />
          </svg>
          <div class="absolute flex justify-center px-12" style="top: 15%">
            <div class="flex flex-col text-left w-5/6">
              <h2 class="text-3xl lg:text-4xl font-bold text-primary-light">
                Asiakaslähtöistä huoltoa
              </h2>
              <p class="leading-relaxed">
                Olemme omistautuneet tekemään elämästänne helpompaa
              </p>
              <custom-button
                class="w-52 mt-2"
                text="Palvelumme"
                path="/palvelut"
                hoverStyle="background-color"
              ></custom-button>
            </div>
          </div>
        </div>
        <div class="hero-picture-slot-container relative z-10">
          <HeroImageSlot />

          <div
            class="absolute inset-0 bottom-2/3 flex justify-center items-center hidden small:block break:hidden"
          >
            <div
              class="relative z-1 top-[24rem] small:top-[24rem] sm:top-[33rem] w-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 751 551"
                fill="none"
              >
                <path
                  d="M751 551L0 423.5V0L751 120.279V551Z"
                  fill="#222526"
                ></path>
              </svg>

              <div
                class="center-content absolute top-0 right-0 bottom-24 left-0 flex flex-col justify-center items-start text-left text-white px-12 py-4 mt-10 sm:mt-0"
              >
                <h2
                  class="text-primary-light sm:text-3xl lg:text-4xl font-bold"
                >
                  Asiakaslähtöistä kiinteistöhuoltoa
                </h2>
                <p class="leading-relaxed">
                  Olemme omistautuneet tekemään elämästänne helpompaa
                </p>
                <custom-button
                  class="w-52 mt-4"
                  text="Soita meille"
                  path="tel:+358 4015 62228"
                  hoverStyle="background-color"
                  iconType="call"
                ></custom-button>
              </div>
            </div>
          </div>
            <div
            class="absolute inset-0 bottom-2/3 flex justify-center items-center small:hidden"
          >
            <div
              class="relative z-1 top-[25rem] phone:top-[30rem] w-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 800 800"
                fill="none"
              >
                <path
                  d="M 800 800 L 0 600.5 V 0 L 800 120.279 V 800 Z"
                  fill="#222526"
                ></path>
              </svg>

              <div
                class="center-content absolute top-0 right-0 bottom-24 left-0 flex flex-col justify-center items-start text-left text-white px-12 py-4 mt-10 sm:mt-0"
              >
                <h2
                  class="text-primary-light sm:text-3xl lg:text-4xl font-bold"
                >
                  Asiakaslähtöistä kiinteistöhuoltoa
                </h2>
                <p class="leading-relaxed">
                  Olemme omistautuneet tekemään elämästänne helpompaa
                </p>
                <custom-button
                  class="w-52 mt-2"
                  text="Soita meille"
                  path="tel:+358 4015 62228"
                  hoverStyle="background-color"
                  iconType="call"
                ></custom-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About Section -->
    <div
      class="relative mt-[24rem] sm:mt-[42rem] break:mt-[21rem] xl:mt-[25rem] 2xl:mt-[40rem] z-10"
    >
      <div class="svg-container bg-svg-background">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 1712"
          fill="none"
        >
          <path
            d="M0 305.761L1920 0.5V1334.25L0 1712V305.761Z"
            fill="#222526"
          />
        </svg>
      </div>
      <div
        class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full flex flex-col break:flex-row justify-center items-center"
      >
        <div
          class="flex flex-col break:flex-row items-center justify-center space-x-0 break:space-x-16 px-12 w-full"
        >
          <div
            class="w-full h-[18rem] sm:h-[26rem] xl:h-[40rem] bg-center bg-cover rounded-lg mb-4 break:mb-0"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/AboutPicture.jpg') + ')',
            }"
          ></div>
          <div class="flex flex-col text-left w-full">
            <h2 class="text-3xl lg:text-4xl font-bold mb-6 text-primary-light">
              Meistä
            </h2>
            <p class="mb-4 leading-relaxed">
              Kiinteistöhuolto Veeti Huotari on uusi ja 
              innovatiivinen yritys, joka tarjoaa laadukkaita 
              kiinteistöhuollon palveluita Vihdin, Lohjan ja Kirkkonummen alueilla.
            </p>
            <p class="leading-relaxed">
              Olemme omistautuneet tarjoamaan asiakkaillemme vertaansa vailla
              olevaa palvelua, jossa yhdistyvät luotettavuus ja ammattitaito,
              oli kyse sitten kiinteistön ylläpidosta, puhtaanapidosta tai
              pienistä korjaustöistä.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Services Section -->
    <div class="flex justify-center items-center flex-col break:flex-row z-10">
      <div
        class="flex flex-col text-left w-full break:w-2/4 px-12 lg:px-24 py-12"
      >
        <h2 class="text-3xl lg:text-4xl font-bold mb-4 text-primary-light">
          Miksi juuri me?
        </h2>
        <p class="leading-relaxed mb-4">
          Tarjoamme monipuoliset kiinteistöhuollon palvelut, mukaan lukien
          piha-alueiden ylläpito ja viherhoitotyöt.
        </p>
        <p class="leading-relaxed mb-4">
          Talvikaudella huolehdimme lumitöistä, varmistaen turvalliset
          kulkureitit ja piha-alueet.
        </p>
        <p class="leading-relaxed mb-4">
          Asiantuntijamme tarjoavat myös asennuspalveluja kodin elektroniikalle,
          sekä kattavat ratkaisut kiinteistön yleishuoltoon ja -siivoukseen,
          sisältäen alueiden puhtaanapidon ja ylläpitotyöt.
        </p>
        <p class="leading-relaxed">
          Kysy lisää räätälöidyistä palveluratkaisuistamme!
        </p>
        <custom-button
          class="w-52 mt-12 mr-12"
          text="Ota Yhteyttä"
          path="/yhteystiedot"
        ></custom-button>
      </div>
      <div
        class="w-full break:h-[95rem] h-[40rem] mb-[40rem] break:mb-0 bg-center bg-cover break:w-1/2 break:absolute break:right-0"
      >
        <img
          :src="require('@/assets/ServicesPicture.jpg')"
          class="object-cover w-full h-full diagonal-image"
        />
      </div>
      <div class="flex-1 invisible"></div>
    </div>
    <!-- Contact Section -->
    <div class="relative flex justify-center items-center">
      <div class="svg-container-bottom svg-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 1300"
          width="960"
          height="650"
          class="absolute bottom-0 break:static"
        >
          <path d="M0 435.5L1920 0V4010.5H0" fill="#222526" />
        </svg>
      </div>
      <div
        class="absolute break:bottom-1/4 bottom-mobile-spacing flex justify-center items-center break:-mb-24 md:mx-16"
      >
        <div
          class="flex flex-col text-text-color bg-background-color p-8 md:p-10 rounded-lg shadow-lg w-full m-4 mb-32 break:mb-0 md:w-3/4"
        >
          <h2 class="text-3xl lg:text-4xl font-bold mb-4 text-primary-light">
            Kiinteistöhuollon palvelut
          </h2>

          <p class="mb-4 leading-relaxed">
            Asiakastyytyväisyys on meille ensisijaisen tärkeää, ja pyrimme aina
            ylittämään odotukset palveluidemme laadun ja tehokkuuden suhteen.
          </p>
          <p class="leading-relaxed">
            Ammattitaitoinen henkilökuntamme varmistaa, että kiinteistönne on
            aina hyvässä kunnossa ja viihtyisä sekä asukkaille että
            vierailijoille.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroImageSlot from "@/components/HeroImageSlot.vue";

export default {
  name: "HomePage",
  components: {
    HeroImageSlot,
  },
};
</script>

<style>
.flex-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.flex-container > * {
  flex: 1;
}
.svg-image,
.hero-picture-slot-container {
  flex: 1;
}
.hero-image-slot {
  width: 100%;
  height: 100%;
}
.svg-container {
  display: block;
  width: 100%;
}
.svg-container svg {
  display: block;
  width: 100%;
  height: auto;
}
.svg-container-bottom {
  pointer-events: none;
}

@media (max-width: 830px) {
  .svg-container svg {
    height: 150vh;
  }
  .svg-container-bottom svg {
    height: 150vh;
  }
  .diagonal-image {
    clip-path: polygon(0 20%, 100% 0, 200% 80%, 0% 100%);
  }
}
</style>
