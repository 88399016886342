import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router'; 
import './assets/tailwind.css';
import CustomButton from './components/CustomButton.vue';

const app = createApp(App);

app.component('custom-button', CustomButton);

app.use(router);

app.mount('#app');